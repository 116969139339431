<template>
  <div>
    <vs-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="validationObserver">
      <form @keydown.enter.prevent="() => { handleSubmit(reportPrayers) }">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4 pt-5">
        <div>
            <tm-select
              :options="yearOptions"
              :reduce="name => name"
              class="w-full"
              placeholder="السنة الهجرية"
              v-model="hijriYear"
              validation-rules=""
            />
        </div>
      <div>
            <tm-input
              class="w-full placeholder"
              placeholder="تاريخ البداية"
              type="date"
              v-model="startDate"
              validation-rules=""
            />
      </div>
      <div>
            <tm-input
              class="w-full placeholder"
              placeholder="تاريخ النهاية"
              type="date"
              v-model="endDate"
              validation-rules=""
            />
      </div>
        <div>
          <tm-select
            :options="typeOptions"
            :reduce="name => name.value"
            class="w-full"
            label="name"
            placeholder="نوع الحساب"
            v-model="accountType"
            @input="setOptions"
            validation-rules=""
          />
        </div>
      <div v-if="accountType === 'RIYAD'">
        <tm-input
          type="string"
          class="w-full"
          placeholder="النادي"
          v-model="clubName"
          @input="setOptions"
          validation-rules=""
        />
      </div>
      <div>
        <tm-select
          :options="groupOptions"
          :reduce="name => name.value"
          label="name"
          class="w-full"
          placeholder="تجميع حسب"
          v-model="groupBy"
          validation-rules=""
        />
      </div>
    </div>
      <div class="py-5 flex justify-center items-center">
        <button
          class="flex items-center btn_Option px-3 ml-4 export"  @click.prevent="handleSubmit(reportPrayers)">
          <feather-icon icon="DownloadIcon" class="mr-2 button_icon" />
          <span>تصدير اكسل</span>
        </button>
      </div>
      </form>
      </ValidationObserver>
    </vs-card>
  </div>
</template>

<script>
  import {UserService} from "../services/user-service/user.service";
  import ImportExcel from "../../../components/excel/ImportExcel";
  import TmInput from "../../shared/shared-components/TmInput";
  import TmSelect from "../../shared/shared-components/TmSelect";

  export default {
    name: "statistics",
    data() {
      return{
        startDate:'',
        endDate:'',
        groupBy:'',
        accountType:'',
        clubName:'',
        yearOptions:['1442','1443','1444','1445'],
        hijriYear:'',
        typeOptions: [
          {
            name: 'موظفين',
            value: 'EMPLOYEE'
          },
          {
            name: 'نوادي',
            value: 'RIYAD'
          }
        ],
        groupOptions: [
          {
            name: 'معرف المستخدم',
            value: 'user_id'
          },
          {
            name: 'نوع الحساب',
            value: 'account_type'
          },
          {
            name: 'النادي',
            value: 'club_name'
          }
        ],
      }
    },
    components: {
      TmInput,
      TmSelect,
      ImportExcel,
    },
    methods:{
      setOptions(){
          this.groupOptions = [
            {
              name: 'معرف المستخدم',
              value: 'user_id'
            },
            {
              name: 'نوع الحساب',
              value: 'account_type'
            },
            {
              name: 'النادي',
              value: 'club_name'
            }
          ];
          if(this.accountType !== '') {
            if (this.accountType === 'RIYAD' && this.clubName === '')
              this.groupOptions = this.groupOptions.filter(function (obj) {
                return obj.value !== 'account_type';
              });
            else if (this.accountType === 'RIYAD' && this.clubName !== '') {
              this.groupOptions = this.groupOptions.filter(function (obj) {
                return obj.value === 'user_id';
              });
            } else this.groupOptions = this.groupOptions.filter(function (obj) {
              return obj.value === 'user_id';
            });
          }
      },
     async  reportPrayers() {
       let params = '';
       if (this.startDate && this.endDate)
       params = params.concat(`from=${this.startDate}&to=${this.endDate}`);
       if(this.groupBy)
         params =  params.concat(`&groupBy=${this.groupBy}`);
       if(this.accountType)
         params =  params.concat(`&accountType=${this.accountType}`);
       if(this.clubName)
         params =  params.concat(`&clubName=${this.clubName}`);
       if(this.hijriYear)
         params =  params.concat(`&hijriYear=${this.hijriYear}`);
       await UserService.reportPrayers(params);
  },
    }
  }
</script>

<style>
  .placeholder .vs-input--placeholder.normal {
  padding-left: 30px !important;
}
</style>
